<div
  class="meu-container login-img row"
  [ngStyle]="{
    'background-color': '#095093'
  }"
>
  <div
    class="left-view login-img"
    [ngStyle]="{
      'background-image': 'url(' + pathImg + 'login-page.png)'
    }"
  ></div>
  <div class="right-view row">
    <div class="col-12 logo-login-page">
      <img class="" src="../../../../assets/media/Image/logo.jpg" />
    </div>
    <div class="col-12 rc-2">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="col-12 footer-login">
    <div class="kt-footer__copyright color-white">
      &nbsp;©&nbsp;{{ today | date : 'yyyy' }}
      <a
        href="http://www.thanglongreal.vn/"
        target="_blank"
        rel="noreferrer"
        class="color-white"
      >
        Thang Long Real Group
      </a>
    </div>
  </div>
</div>
